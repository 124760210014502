import { ExploreFiltersBEType, ExploreFiltersType } from '@/types/filters'
import { isEmpty, isNil, omit } from 'lodash'
import { Option } from '@/types/common'
import { FILTER_NAME } from '@/pages/Explore/constants/filters'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { format } from 'date-fns'

export const mapToFilterBE = (values: ExploreFiltersType): ExploreFiltersBEType => {
  return Object.keys(values).reduce((result, key) => {
    const value = values[key as keyof ExploreFiltersType]

    if (isNil(value) || isEmpty(value)) return result

    return {
      ...result,
      [key]: mapValue(key, value)
    }
  }, {})
}

const mapValue = (name: string, value: Option | Option[] | number | string) => {
  switch (name) {
    case FILTER_NAME.country_code:
    case FILTER_NAME.site:
      return (value as Option)?.value

    case FILTER_NAME.report_period:
    case FILTER_NAME.function_code:
    case FILTER_NAME.status:
    case FILTER_NAME.duration:
    case FILTER_NAME.level:
    case FILTER_NAME.people_impacted:
    case FILTER_NAME.movement_supported:
    case FILTER_NAME.type_of_initiative:
      return (value as Option[]).map(item => item.value)

    case FILTER_NAME.fy:
      return Number((value as Option)?.value)

    default:
      return !Number.isNaN(value) ? Number(value) : value
  }
}

export const filterData = (data: InitiativesListItemType[], filters: ExploreFiltersBEType) => {
  return data.filter(item => {
    return Object.keys(filters).every(key => {
      const value = item[key as keyof InitiativesListItemType]
      const filter = filters[key as keyof ExploreFiltersBEType]

      switch (key) {
        case FILTER_NAME.country_code:
        case FILTER_NAME.site:
          return value === filters[key as keyof ExploreFiltersBEType]

        case FILTER_NAME.report_period:
          return (filter as string[]).some(item => value === format(new Date(item), 'MMMM yyyy'))

        case FILTER_NAME.function_code:
        case FILTER_NAME.status:
        case FILTER_NAME.duration:
        case FILTER_NAME.level:
          return (filter as string[]).some(item => value === item)

        case FILTER_NAME.people_impacted:
        case FILTER_NAME.movement_supported:
        case FILTER_NAME.type_of_initiative:
          return (filter as string[]).some(item => (value as string[]).some(valueItem => item === valueItem))

        default:
          return filter === value
      }
    })
  })
}

export const mapToExport = (data: InitiativesListItemType[]) => {
  return data.map(item => {
    return omit(
      {
        ...item,
        initiative_id: item.id
      },
      'id'
    )
  })
}
