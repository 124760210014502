import React from 'react'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { LEVEL_OF_CHANGE_OPTIONS } from '@/pages/ReportForm/constants/options'
import { FormLabel } from '@/pages/ReportForm/components/Labels'
import { DynamicTranslations } from '@/components/translations/Translations'
import { RadioGroup } from '@/components/form/radio/RadioGroup'
import { useField, useFormikContext } from 'formik'
import { InitiativeFormType } from '@/types/initiative'
import InlineMessage from '@ingka/inline-message'

export const LevelField = () => {
  const { values } = useFormikContext<InitiativeFormType>()
  const [, , helpers] = useField(FIELD_NAME.level)

  React.useEffect(() => {
    if (!values?.type_of_initiative) return

    if (values.type_of_initiative.length > 1) {
      helpers.setValue(LEVEL_OF_CHANGE_OPTIONS[1])
    } else {
      helpers.setValue(LEVEL_OF_CHANGE_OPTIONS[0])
    }
  }, [values])

  return (
    <RadioGroup
      name={FIELD_NAME.level}
      list={LEVEL_OF_CHANGE_OPTIONS}
      label={<FormLabel id={FIELD_NAME.level} />}
      AdditionalEl={<InlineMessage body={<DynamicTranslations id="form.field.level.helper" withTags />} />}
      disabled
    />
  )
}
