import React from 'react'
import { isEmpty } from 'lodash'
import styles from '../../styles/Initiatives.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { CommentInfo } from '@/pages/Initiatives/components/Details/CommentInfo'
import { USER_ROLES } from '@/constants/common'
import {
  DURATION_OPTIONS,
  DURATION_OPTIONS_OLD,
  PEOPLE_IMPACTED_OPTIONS,
  TYPE_OF_INITIATIVE_OPTIONS
} from '@/pages/ReportForm/constants/options'
import { Select } from '@/pages/Initiatives/components/Select/Select'
import { setToChangeField } from '@/pages/Initiatives/utils/edit'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { InitiativeType } from '@/types/initiative'
import { EDIT_WITH_STATUSES } from '@/constants/statuses'
import { Attachments } from '@/pages/Initiatives/components/Details/Attachments'
import { DynamicTranslations } from '@/components/translations/Translations'
import { Show } from '@/components/conditions/Show'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'

type Props = {
  detailsData?: InitiativesListItemType | null
  readonly?: boolean
}

const InfoTabImpl: React.FC<Props> = props => {
  const { readonly, detailsData } = props
  const initiativeDetails = useSelector((state: RootState) => state.initiatives.details)
  const details = detailsData || initiativeDetails
  const role = useSelector((state: RootState) => state.auth.user?.role)
  if (!details) return null
  const {
    site,
    country_entry,
    report_period,
    partner,
    people_impacted,
    people_impacted_comment,
    people_supported_count,
    participants_of_skills_development,
    main_movement_supported,
    movement_supported,
    type_of_initiative,
    type_of_initiative_comment,
    spent,
    level,
    duration,
    coworkers_count,
    coworker_hours,
    customers_count,
    comment,
    status,
    fy
  } = details

  const reportPeriod = report_period || '-'
  const peopleImpacted = !isEmpty(people_impacted)
    ? people_impacted?.map(item => PEOPLE_IMPACTED_OPTIONS.find(option => option.value === item)?.label).join(', ')
    : '-'
  const movement = !isEmpty(movement_supported) ? movement_supported?.join(', ') : '-'
  const typeOfInitiative = !isEmpty(type_of_initiative) ? type_of_initiative?.join(', ') : ''

  const isNewFYStructure = Number(fy) >= 2025

  const Duration =
    !readonly && role === USER_ROLES.countryManager && EDIT_WITH_STATUSES.some(item => item === status) ? (
      <Select
        value={duration || ''}
        list={isNewFYStructure ? DURATION_OPTIONS : DURATION_OPTIONS_OLD}
        onChange={setToChangeField(FIELD_NAME.duration as keyof InitiativeType)}
      />
    ) : (
      duration || '-'
    )

  return (
    <div className={styles.Information}>
      <div className={styles.ContentTable}>
        <span>
          <DynamicTranslations id="initiatives.details.unit_name" />:
        </span>
        {site || country_entry || '-'}
        <span>
          <DynamicTranslations id="initiatives.details.reporting_period" />:
        </span>
        {reportPeriod}
        <span>
          <DynamicTranslations id="initiatives.details.partners" />:
        </span>
        {partner || '-'}
      </div>
      <div className={styles.ContentTable}>
        <span>
          <DynamicTranslations id="initiatives.details.people_impacted" />:
        </span>
        {peopleImpacted || '-'}
        {people_impacted_comment ? `, ${people_impacted_comment}` : ''}
        <span>
          <DynamicTranslations id="initiatives.details.number_of_people" />:
        </span>
        {people_supported_count ?? '-'}
        <Show when={type_of_initiative?.some(item => item === TYPE_OF_INITIATIVE_OPTIONS[2].value) || false}>
          <span>
            <DynamicTranslations id="initiatives.details.participants_of_skill_development" />:
          </span>
          {participants_of_skills_development ?? '-'}
        </Show>
        <Show when={isNewFYStructure}>
          <span>
            <DynamicTranslations id="initiatives.details.main_movement" />:
          </span>
          {main_movement_supported ?? '-'}
        </Show>
        <span>
          <DynamicTranslations
            id={Number(fy) >= 2025 ? 'initiatives.details.other_movement' : 'initiatives.details.movement'}
          />
          :
        </span>
        {movement ?? '-'}
        <span>
          <DynamicTranslations id="initiatives.details.type_of_initiative" />:
        </span>
        {typeOfInitiative || '-'}
        {type_of_initiative_comment ? `, ${type_of_initiative_comment}` : ''}
        <span>
          <DynamicTranslations
            id={isNewFYStructure ? 'initiatives.details.donation_amount' : 'initiatives.details.budget'}
          />
          :
        </span>
        {spent ?? '-'}
      </div>
      <div className={styles.ContentTable}>
        <span>
          <DynamicTranslations
            id={isNewFYStructure ? 'initiatives.details.type_of_contribution' : 'initiatives.details.level'}
          />
          :
        </span>
        {level || '-'}
        <span>
          <DynamicTranslations
            id={isNewFYStructure ? 'initiatives.details.duration_of_partnership' : 'initiatives.details.duration'}
          />
          :
        </span>
        {Duration}
        <span>
          <DynamicTranslations id="initiatives.details.coworkers_engaged" />:
        </span>
        {coworkers_count ?? '-'}
        <span>
          <DynamicTranslations id="initiatives.details.hours" />:
        </span>
        {coworker_hours ?? '-'}
        <span>
          <DynamicTranslations id="initiatives.details.customer_and_citizens" />:
        </span>
        {customers_count ?? '-'}
        <span>
          <DynamicTranslations id="initiatives.details.comment" />:
        </span>
        <CommentInfo comment={comment} />
        <span>
          <DynamicTranslations id="initiatives.details.attachments" />:
        </span>
        <Attachments />
      </div>
    </div>
  )
}

export const InfoTab = React.memo(InfoTabImpl)
